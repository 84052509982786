import React from "react";
export const Contacts = () => <>
                                <h1>CONTACTS</h1>
<p>To reach out to our team please email directly to:</p>
<ul>
<li>Sasha Krymova sasha@dearprogress.com</li>
<li>Denis Erkhov denis@dearprogress.com</li>
</ul>
</>;
export default Contacts;
